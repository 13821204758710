import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from 'react-router-dom';
import CUGamingLogo from "../../assets/cugaming-logo.png"
import {Avatar, Grid} from "@mui/material";
// import HomeIcon from '@mui/icons-material/Home';
// import InfoIcon from '@mui/icons-material/Info';
// import GroupIcon from '@mui/icons-material/Group';
// import LeaderboardIcon from '@mui/icons-material/Leaderboard';
// import FaceIcon from '@mui/icons-material/Face';

export const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export function NavBar() {
    return (
        <AppBar position="fixed" open={true}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => null}
                    edge="start"
                    sx={{ mr: 2, ...(true && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                    The University of Colorado
                </Typography>
            </Toolbar>
        </AppBar>)
}

export function Sidebar() {
    const theme = useTheme();

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={true}
        >
            <DrawerHeader>
                {/*<IconButton onClick={() => null}>*/}
                {/*    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}*/}
                {/*</IconButton>*/}
                <Grid container justifyContent="center">
                    <Link to={'/'}>
                        <Avatar
                            alt="CU Gaming Logo"
                            src={CUGamingLogo}
                            variant="rounded"
                            sx={{
                                width: 96,
                                height: 96,
                                marginBottom: -8,
                            }}
                        />
                    </Link>
                </Grid>
            </DrawerHeader>
            <Divider />
            <List sx={{marginTop: 6}}>
                <ListItem button key='Home' component={Link} to="/">
                    <ListItemText primary='Home' />
                </ListItem>
                {['Leadership', 'Recruitment', 'Teams', 'Intramurals'].map((text, index) => (
                    <ListItem button key={text} component={Link} to={text}>
                        {/* <ListItemIcon>
                            {index === 0 ? <HomeIcon/> : index === 1 ? <InfoIcon/> : index === 2 ? <GroupIcon/> : null}
                        </ListItemIcon> */}
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </Drawer>
    )
}
